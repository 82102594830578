import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiPageBody,
} from "@elastic/eui";
import { useNavigate } from "react-router-dom";
import discord from "../images/Discord.svg";

function Header(props) {
  const navigate = useNavigate();
  const { width, breakPoint, logo } = props;

  return (
    <EuiHeader
      style={{
        backgroundColor: "#e1cdbc",
        border: "none",
        width: "100%",
        boxShadow: "none",
      }}
    >
      <EuiHeaderSectionItem>
        {logo != props.companyName ? (
          <img
            src={logo}
            style={{ width: "130px", height: "50px" }}
            onClick={() => navigate("/")}
          />
        ) : (
          <h1 onClick={() => navigate("/")} style={{ fontSize: 20 }}>
            {props.companyName}
          </h1>
        )}
      </EuiHeaderSectionItem>

      <EuiHeaderSectionItem>
        <EuiHeaderLinks aria-label="App navigation links example">
          {width > breakPoint &&
            props.services.map((service, index) => {
              if (index == 0 || index == 1 || index == 2) {
                return;
              }
              return (
                <EuiHeaderLink
                  onClick={() => navigate(`Service${index}`)}
                  isActive
                  style={{
                    fontWeight: 900,
                    border: "none",
                    background: "none",
                    color: "black",
                  }}
                >
                  {service.name}
                </EuiHeaderLink>
              );
            })}
          <EuiHeaderLink
            onClick={() => navigate(`showcase`)}
            isActive
            style={{
              fontWeight: 900,
              border: "none",
              background: "none",
              color: "black",
            }}
          >
            Showcase
          </EuiHeaderLink>
          <EuiHeaderLink
            style={{
              fontWeight: 900,
              border: "none",
              background: "black",
              color: "white",
              textDecoration: "none",
            }}
            onClick={() => {
              window.gtag("config", "AW-11468513429");

              window.gtag("event", "conversion", {
                send_to: "AW-11468513429/oRmmCNqywMwZEJXBztwq",
              });
            }}
            href={`https://discord.gg/JeetUpxURH`}
            isActive
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                width={"30px"}
                style={{ cursor: "pointer", marginRight: "5px" }}
                src={discord}
              />
              SignUp
            </div>
          </EuiHeaderLink>
        </EuiHeaderLinks>
      </EuiHeaderSectionItem>
    </EuiHeader>
  );
}
export default Header;
