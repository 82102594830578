import tools from "../images/default/tools.jpg";
import image1 from "../images/default/image1.jpg";
import image2 from "../images/default/image2.jpg";
import image3 from "../images/default/image3.jpg";
import image4 from "../images/default/image4.jpg";
import prettytragedyy_image from "../images/default/prettytradgedy.jpg";
import service0 from "../images/default/service0.jpg";
import service1 from "../images/default/service1.jpg";
import service2 from "../images/default/service2.jpg";
//import logo from "../images/default/logo.svg";
const logo = "";
export const start_date = "xxxx";
const companyName = "Manga Mkr";
export const props = {
  logo: companyName,
  companyName: companyName,
  phoneNumber: "<- Start Creating Now!",
  address: "123 lane",
  email: "default@gmail.com",
  start_date: start_date,
  sectionOne: {
    mainTitle: "Manga Mkr",
    subTitle: "Custom AI manga at your fingertips",
    extraText: "",
    image: null,
  },
  sectionTwo: {
    mainTitle: "What is MangaMkr?",
    subTitle: ``,
    extraText: `Mangamkr is an AI generative tool that takes your ideas and makes them into reality.
Have a story that you want to create but don’t know how to draw?
Want to make a small recap manga about a stream you just had?
Want to make a cute gift for your girlfriend/boyfriend?
Use Mangamkr.`,
    image: image2,
  },
  sectionThree: {
    mainTitle: "Join the Beta!",
    subTitle: ``,
    extraText: `Our official Discord has all of the information you might need as well as the areas you can create
your manga. As of right now manga creation is only going to be available in the Official Discord.<br/><br/>
/imagine <br/><br/>
In any of our #[][][][]-generator channels, use the command /imagine and you prompt to start
creating your own manga.<br/><br/>
Help us build! <br/><br/>
If you come across an issue let us know in our issue section in Discord!`,
    image: image3,
  },
  as_seen_on: {
    title: "As Seen On",
    items: [
      {
        link: "https://kick.com/maquille",
        name: "Maquille's Kick",
        description: "",
        icon: image4,
        pageTitleOne: "About kevkevin",
        cardDescription: `White Collar Streamer who loves to play Blackjack, Poker, Roulette, and Baccarat.`,
        bodyOne: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
          </div>
        ),
        pageTitleTwo: "??",
        bodyTwo: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
            <img
              style={{ marginTop: "40px", width: "100%", maxWidth: "600px" }}
              src={tools}
            />
          </div>
        ),
      },
      {
        link: "https://kick.com/prettytragedyy",
        name: "PrettyTragedyy's Kick",
        description: "",
        icon: prettytragedyy_image,
        pageTitleOne: "About kevkevin",
        cardDescription: `Hi, I'm Heidi, an Egyptian Princess 💕I am a Counter Strike Fanatic also the Pretty thing that lives in the house. 💕Diagnosed with Multiple Sclerosis at the age of 24 💕Join me for a Fun Chat 💕Daily Streamer 💕Midnight True Horror Stories💕`,
        bodyOne: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
          </div>
        ),
        pageTitleTwo: "??",
        bodyTwo: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
            <img
              style={{ marginTop: "40px", width: "100%", maxWidth: "600px" }}
              src={tools}
            />
          </div>
        ),
      },
    ],
  },
  services: {
    title: "Our Founders",
    items: [
      {
        name: "kevkevin",
        description: "",
        icon: service0,
        pageTitleOne: "About kevkevin",
        cardDescription: `As the co-founder and the brains behind our tech, coding is my strong suit. If something's broken,
chances are I'm already working on a fix.`,
        bodyOne: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
          </div>
        ),
        pageTitleTwo: "??",
        bodyTwo: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
            <img
              style={{ marginTop: "40px", width: "100%", maxWidth: "600px" }}
              src={tools}
            />
          </div>
        ),
      },
      {
        name: "Brian",
        description: "",
        cardDescription: `As the co-founder and creative force behind our brand, I oversee all things marketing. If it's
marketing-related, you'll see my name on it.`,
        icon: service1,
        pageTitleOne: "Service 3",
        bodyOne: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt lorem quam, vel egestas massa gravida ut. Etiam
              ullamcorper mauris nec imperdiet lobortis.
            </p1>
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            ></p1>
            <img
              style={{ marginTop: "40px", width: "100%", maxWidth: "600px" }}
              src={tools}
            />
          </div>
        ),
        pageTitleTwo: "",
        bodyTwo: <div></div>,
      },
      {
        name: "Resources",
        description: "",
        cardDescription:
          "Learn more about who we are and what we aim to achieve with this project",
        icon: service2,
        pageTitleOne: "Resources",
        bodyOne: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            ></p1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            tincidunt lorem quam, vel egestas massa gravida ut. Etiam
            ullamcorper mauris nec imperdiet lobortis.
            <p1
              style={{
                textAlign: "left",
                marginBottom: "8px",
                paddingRight: "15vw",
              }}
            ></p1>
            <img
              style={{ marginTop: "40px", width: "100%", maxWidth: "600px" }}
              src={tools}
            />
          </div>
        ),
        pageTitleTwo: "",
        bodyTwo: <div></div>,
      },
    ],
  },
};
export const socialMediaLinks = [
  {
    label: "Facebook",
    href: "#/display/list-group",
    iconType: "calendar",
  },
  {
    label: "Youtube",
    href: "#/display/list-group",
    isActive: true,
    iconType: "clock",
  },
  {
    label: "Twitter",
    href: "https://x.com/mangamkr",
    isDisabled: false,
    iconType: "compute",
  },
  {
    label: "Instagram",
    isDisabled: false,
    iconType: "compute",
    href: "https://www.instagram.com/mangamkr",
  },
  {
    label: "Discord",
    isDisabled: false,
    iconType: "compute",
    href: "https://discord.gg/JeetUpxURH",
  },
];
