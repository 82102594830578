function ServiceOnePage(props) {
  const { pageTitleOne, bodyOne, pageTitleTwo, bodyTwo } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
							width: "auto",
        alignSelf: "flex-start",
      }}
    >
      <h1>{pageTitleOne}</h1>
      {bodyOne}
      <h1>{pageTitleTwo}</h1>
      {bodyTwo}
    </div>
  );
}
export default ServiceOnePage;
