import "../App.css";
import { useState, useEffect, useRef } from "react";
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiPageBody,
  EuiButton,
} from "@elastic/eui";
import SectionOne from "./SectionOne";
import Services from "./Services";
import ContactUs from "./ContactUs";
import Calendy from "./Calendy";
import discord from "../images/Discord.svg";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Home(props) {
  const { breakPoint, width, phoneNumber } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="App"
    >
      <SectionOne
        width={width}
        breakPoint={breakPoint}
        mainTitle={props.sectionOne.mainTitle}
        subTitle={props.sectionOne.subTitle}
        extraText={props.sectionOne.extraText}
        imageOnRight={true}
        image={props.sectionOne.image}
        button={
          <EuiHeaderLink
            style={{
              fontWeight: 900,
              border: "none",
              background: "black",
              color: "white",
              textDecoration: "none",
            }}
            href={`https://discord.gg/JeetUpxURH`}
            onClick={() => {
              window.gtag("config", "AW-11468513429");

              window.gtag("event", "conversion", {
                send_to: "AW-11468513429/oRmmCNqywMwZEJXBztwq",
              });
            }}
            isActive
          >
            <h1 style={{ fontSize: 24, display: "flex", alignItems: "center" }}>
              <img
                width={"30px"}
                style={{ cursor: "pointer", marginRight: "5px" }}
                src={discord}
              />
              Start Creating Now!
            </h1>
          </EuiHeaderLink>
        }
      />
      <SectionOne
        width={width}
        breakPoint={800}
        mainTitle={props.sectionTwo.mainTitle}
        subTitle={props.sectionTwo.subTitle}
        extraText={props.sectionTwo.extraText}
        imageOnRight={false}
        image={props.sectionTwo.image}
      />
      <SectionOne
        width={width}
        breakPoint={800}
        mainTitle={props.sectionThree.mainTitle}
        subTitle={props.sectionThree.subTitle}
        extraText={props.sectionThree.extraText}
        imageOnRight={true}
        button={
          <EuiHeaderLink
            style={{
              fontWeight: 900,
              border: "none",
              background: "black",
              color: "white",
              textDecoration: "none",
            }}
            href={`https://discord.gg/JeetUpxURH`}
            onClick={() => {
              window.gtag("config", "AW-11468513429");

              window.gtag("event", "conversion", {
                send_to: "AW-11468513429/oRmmCNqywMwZEJXBztwq",
              });
            }}
            isActive
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                width={"30px"}
                style={{ cursor: "pointer", marginRight: "5px" }}
                src={discord}
              />
              Join The Beta!
            </div>
          </EuiHeaderLink>
        }
        image={props.sectionThree.image}
      />
      <Services services={props.as_seen_on} />
      <Services services={props.services} />
      {/*<div
        style={{
          display: width < 800 ? "inline" : "flex",
          marginTop: "100px",
          background: "rgb(26 32 44 )",
          justifyContent: "center",
          paddingLeft: "auto",
          paddingRight: "auto",
          paddingTop: "40px",
          paddingBottom: "40px",
          width: "100vw",
        }}
      >
        <ContactUs width={width} breakPoint={800} />
        <Calendy width={width} breakPoint={800} />
      </div>*/}
    </div>
  );
}

export default Home;
