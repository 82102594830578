import "./App.css";
import { useState, useEffect } from "react";
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiPageBody,
  EuiButton,
} from "@elastic/eui";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import Showcase from "./Showcase";
import ServiceOnePage from "./ServiceOnePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  start_date,
  props,
  socialMediaLinks,
} from "./settings/settingsDefault";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function App() {
  const breakPoint = 1200;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isBelowBreakPoint = windowDimensions.width < breakPoint;
  return (
    <BrowserRouter>
      <div
        style={{
          padding: "2rem",
          paddingBottom: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "1000px",
        }}
        className="App"
      >
        <Header
          breakPoint={800}
          width={windowDimensions.width}
          companyName={props.companyName}
          services={props.services.items}
          logo={props.logo}
        />
        <Routes path="/">
          <Route
            index
            element={
              <Home
                {...props}
                breakPoint={breakPoint}
                width={windowDimensions.width}
              />
            }
          />
          {props.services.items.map((service, index) => {
            return (
              <Route
                path={`Service${index}`}
                element={<ServiceOnePage {...service} />}
              />
            );
          })}
          <Route path="showcase" element={<Showcase />} />
          <Route path="*" element={<div>Error 404 page not found</div>} />
        </Routes>
        <Footer
          phoneNumber={props.phoneNumber}
          address={props.address}
          email={props.email}
          width={windowDimensions.width}
          breakPoint={800}
          socialMediaLinks={socialMediaLinks}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
