import { EuiIcon, EuiFlexItem, EuiCard, EuiFlexGroup } from "@elastic/eui";
import { useNavigate } from "react-router-dom";

function Services(props) {
  console.log("hello");
  console.log(props);
  const navigate = useNavigate();
  const services = props.services.items;
  const theServices = services.map((service, index) => {
    return (
      <EuiFlexItem style={{ maxWidth: "300px" }}>
        <EuiCard
          title={service.name}
          description={service.cardDescription}
          style={{ backgroundColor: "#e1cdbc" }}
          icon={
            <img
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "100%",
                objectFit: "cover",
              }}
              src={service.icon}
            />
          }
          onClick={() => {
            if (index != 0 && index != 1) {
              navigate(`/Service${index}`);
            }
            if (service.link) {
              var win = window.open(service.link, "_blank");
              win.focus();
            }
          }}
        />
      </EuiFlexItem>
    );
  });
  return theServices;
}
export default (props) => (
  <div style={{ marginTop: "40px", paddingBottom: "50px" }}>
    <div style={{ display: "inline-flex" }}>
      <h1
        style={{
          marginBottom: "0px",
          marginRight: "10px",
          textAlign: "center",
          fontSize: "2.875rem",
          fontWeight: 900,
          color: "#e1cdbc",
          marginBottom: "20px",
        }}
      >
        {props.services.title}
      </h1>
    </div>
    <EuiFlexGroup
      style={{ display: "flex", justifyContent: "center" }}
      gutterSize="xl"
    >
      <Services {...props} />
    </EuiFlexGroup>
  </div>
);
