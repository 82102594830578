import { EuiHeader, EuiListGroup, EuiFlexGroup } from "@elastic/eui";
import facebook from "../images/Facebook.svg";
import twitter from "../images/Twitter.svg";
import youtube from "../images/Youtube.svg";
import discord from "../images/Discord.svg";
import instagram from "../images/Instagram.svg";

function Footer(props) {
  const { width, breakPoint, address, phoneNumber, email } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: width < breakPoint ? "column" : "row",
        backgroundColor: "#e1cdbc",
        width: "98vw",
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingBottom: "50px",
        paddingTop: "50px",
      }}
    >
      {width > breakPoint && (
        <EuiFlexGroup
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
            paddingBottom: width < breakPoint ? "30px" : "0px",
            paddingRight: width < breakPoint ? "40px" : "0px",
          }}
        >
          <p1 style={{ textAlign: "center" }}>
            © 2024 MangaMkr Inc. All Rights Reserved.
          </p1>
        </EuiFlexGroup>
      )}
      <EuiFlexGroup
        style={{
          display: "flex",
          height: "100%",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {props.socialMediaLinks.map((element) => {
          const handleClick = (href) => {
            window.gtag("config", "AW-11468513429");

            window.gtag("event", "conversion", {
              send_to: "AW-11468513429/oRmmCNqywMwZEJXBztwq",
            });

            window.open(href, "_blank");
          };
          switch (element.label) {
            case "Discord":
              return (
                <img
                  width={"30px"}
                  onClick={() => handleClick(element.href)}
                  style={{ cursor: "pointer" }}
                  src={discord}
                />
              );
            case "Twitter":
              return (
                <img
                  width={"30px"}
                  src={twitter}
                  onClick={() => handleClick(element.href)}
                  style={{ cursor: "pointer" }}
                />
              );
            case "Instagram":
              return (
                <img
                  width={"30px"}
                  src={instagram}
                  onClick={() => handleClick(element.href)}
                  style={{ cursor: "pointer" }}
                />
              );
            //case "Youtube":
            //  return <img width={"30px"} src={youtube} />;
            //case "Facebook":
            //  return <img width={"30px"} src={facebook} />;
          }
        })}
      </EuiFlexGroup>
      {width <= breakPoint && (
        <p1 style={{ textAlign: "center", marginTop: "40px" }}>
          © 2024 Enconu Inc. All Rights Reserved.
        </p1>
      )}
    </div>
  );
}
export default Footer;
