import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiPageBody,
  EuiButton,
} from "@elastic/eui";

function SectionOne(props) {
  const {
    width,
    breakPoint,
    mainTitle,
    subTitle,
    extraText,
    button,
    imageOnRight,
    image,
  } = props;

  let flexDirectionText =
    width < breakPoint ? "column" : imageOnRight ? "row" : "row-reverse";

  if (image == null) {
    flexDirectionText = null;
  }

  return (
    <EuiPageBody
      style={{
        width: "100%",
        display: "flex",
        flexDirection: flexDirectionText,
        marginTop: "50px",
        alignItems: "center",
        justifyContent: width < breakPoint ? "center" : "space-between",
        maxWidth: "1280px",
      }}
    >
      <div
        width={width < breakPoint ? "50%" : "100%"}
        style={{
          marginRight: width < breakPoint ? "0px" : "10px",
          marginLeft: "0px",
        }}
      >
        <h1
          style={{
            marginBottom: "0px",
            textAlign: width < breakPoint || image == null ? "center" : "left",
            fontSize: "2.875rem",
            fontWeight: 900,
            color: "#e1cdbc",
          }}
        >
          {mainTitle}
        </h1>
        <div
          style={{
            marginTop: "0px",
            marginBottom: "10px",
            textAlign: width < breakPoint || image == null ? "center" : "left",
          }}
        >
          {subTitle.split("<br/>").map((el) => {
            return (
              <h2
                style={{
                  color: "#f7f4ef",
                  display: "inline",
                  transform:
                    "translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
                  paddingLeft: width < breakPoint ? "1rem" : "0rem",
                  paddingRight: width < breakPoint ? "1rem" : "0rem",
                  fontSize: "1.275rem",
                  fontWeight: 900,
                }}
              >
                {el}
                <br />
              </h2>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: width < breakPoint ? "center" : "flex-start",
            textAlign: width < breakPoint ? "left" : "left",
          }}
        >
          <div>
            {extraText.split("<br/>").map((el) => {
              return (
                <p1
                  style={{
                    maxWidth: "32rem",
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    color: "#f7f4ef",
                  }}
                >
                  {el}
                  <br />
                </p1>
              );
            })}
          </div>
        </div>
        {button && (
          <div
            style={{
              float: width < breakPoint || image == null ? "center" : "left",
              marginTop: "1rem",
            }}
          >
            {button}
          </div>
        )}
      </div>
      <img
        width={"100%"}
        style={{
          borderRadius: "2.5rem",
          marginTop: "20px",
          maxWidth: "600px",
          maxHeight: "400px",
          marginRight: !imageOnRight && width < breakPoint ? "0px" : "10px",
          minWidth: "300px",
        }}
        altText={
          "AI art image creation llama3 stablediffusion midjourney MangaMkr"
        }
        src={image}
      />
    </EuiPageBody>
  );
}
export default SectionOne;
