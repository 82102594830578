import React, { useState, useEffect } from "react";

function Showcase() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const BASE_URL = "https://api.mangamkr.com"; // Define the base URL

    const fetchFileNames = async () => {
      try {
        const response = await fetch(`${BASE_URL}/showcase/files`);
        if (!response.ok) {
          throw new Error("Failed to fetch file names");
        }
        let fileNames = await response.json();
        fileNames = fileNames.reverse();
        console.log(fileNames); // Inspect file names

        const fetchImage = async (fileName) => {
          try {
            const imageResponse = await fetch(
              `${BASE_URL}/showcase/file/${fileName}`
            );
            if (!imageResponse.ok) {
              throw new Error(`Failed to fetch image: ${fileName}`);
            }
            const blob = await imageResponse.blob();
            const imageUrl = URL.createObjectURL(blob);
            setImages((prevImages) => {
              // Check if imageUrl already exists in state
              if (!prevImages.includes(imageUrl)) {
                return [...prevImages, imageUrl];
              }
              return prevImages;
            });
          } catch (err) {
            setError(err.message);
          }
        };

        fileNames.forEach((fileName) => fetchImage(fileName));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    console.log("i fire once");

    fetchFileNames();
  }, []);

  useEffect(() => {
    return () => {
      // Clean up URLs when the component unmounts
      images.forEach((imageUrl) => URL.revokeObjectURL(imageUrl));
    };
  }, [images]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {images.length === 0 ? (
          <p>No images found</p>
        ) : (
          images.map((imageUrl, index) => (
            <div key={index} style={{ margin: "3px" }}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                style={{
                  maxWidth: "500px",
                  height: "300px",
                  objectFit: "cover",
                }}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Showcase;
